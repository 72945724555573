// THIS FILE IS GENERATED, edit ./other/svg-icon-template.txt instead
// then run "npm run gen-svg-sprite"

import { type SVGProps } from 'react'
import { cn } from '~/utils/components.ts'
import href from './icon.svg'
export { href }

const sizeClassName = {
	font: 'w-[1em] h-[1em]',
	xs: 'w-3 h-3',
	sm: 'w-4 h-4',
	md: 'w-5 h-5',
	lg: 'w-6 h-6',
	xl: 'w-7 h-7',
	'2xl': 'w-8 h-8',
} as const

export type Size = keyof typeof sizeClassName

const childrenSizeClassName = {
	font: 'gap-1',
	xs: 'gap-1',
	sm: 'gap-1',
	md: 'gap-2',
	lg: 'gap-2',
	xl: 'gap-3',
	'2xl': 'gap-3',
} satisfies Record<Size, string>

/**
 * Renders an SVG icon. The icon defaults to the size of the font. To make it
 * align vertically with neighboring text, you need to wrap the icon and text
 * in a common parent and set the parent to display "flex" (or "inline-flex").
 * Alternatively, if you're ok with the icon being to the left of the text,
 * you can pass the text as a child of the icon and it will be automatically
 * aligned.
 */
export function Icon({
	name,
	size = 'font',
	className,
	children,
	...props
}: SVGProps<SVGSVGElement> & {
	name: IconName
	size?: Size
}) {
	if (children) {
		return (
			<span className={`inline-flex ${childrenSizeClassName[size]}`}>
				<Icon name={name} size={size} className={className} {...props} />
				{children}
			</span>
		)
	}
	return (
		<svg
			{...props}
			className={cn(sizeClassName[size], 'inline self-center', className)}
		>
			<use href={`${href}#${name}`} />
		</svg>
	)
}

export const iconNames = [
  "absence",
  "add-event",
  "add-time-2",
  "add-time",
  "add-user",
  "alert-circle",
  "alert-octagon",
  "arroba",
  "arrow-down",
  "arrow-up",
  "arrowhead-up",
  "avatar",
  "balayeuse-de-route",
  "bin",
  "calendar",
  "calendar2",
  "camera",
  "caret-sort",
  "check-circled",
  "check",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "chevron-up",
  "chevron",
  "cookie",
  "copy",
  "cross-circled",
  "cross",
  "cross2",
  "dashboard",
  "dashboard2",
  "deadline",
  "dot-filled",
  "dots-horizontal",
  "double-arrow-down",
  "double-arrow-left",
  "double-arrow-right",
  "double-arrow-up",
  "down",
  "draggable",
  "drive",
  "edit",
  "editPen2",
  "editPen3",
  "editpen",
  "email-sign",
  "exit",
  "eye-none",
  "eye",
  "fast-forward",
  "filter",
  "gear",
  "help",
  "home",
  "list",
  "location",
  "loupe",
  "mail",
  "man",
  "map-outlined",
  "map-pin-off",
  "map-pin",
  "map-pinned",
  "mixer-horizontal",
  "mixer-vertical",
  "move-horizontal",
  "move-vertical",
  "padlock",
  "pin-off",
  "pin",
  "planet-earth",
  "plus-carre-outlined",
  "plus-carre",
  "plus-circled",
  "plus-outlined",
  "plus-rond-gradient-outlined",
  "plus",
  "print",
  "route",
  "search",
  "see",
  "settings",
  "shield",
  "signe-darroba",
  "success",
  "table",
  "table2",
  "task-planning",
  "task-planning2",
  "time-management",
  "todo",
  "trash",
  "triangle-down",
  "triangle-left",
  "triangle-right",
  "triangle-up",
  "truck",
  "user-round-check",
  "user-round-plus",
  "user-round-search",
  "user-round-x",
  "user-round",
  "user",
  "user2",
  "users-round",
  "utilisateurs-full",
  "utilisateurs-outlined",
  "view",
  "warehouse",
  "warning",
  "wrench",
  "x-octagon",
  "zamas_logo",
] as const;
export type IconName = typeof iconNames[number];